<template>
  <div class="d-flex">
    <a
      href="#"
      class="ml-social-btn2 mr-6"
      @click.prevent="selectChannel(6)"
      v-if="isTelegram"
      :class="{ active: channel === 6 }"
    >
      <SvgTelegram class="ml-social-btn2_icon" />
    </a>
    <a
      href="#"
      class="ml-social-btn2 mr-6"
      @click.prevent="selectChannel(9)"
      v-if="isWhatsapp"
      :class="{ active: channel === 9 }"
    >
      <SvgWhatsApp class="ml-social-btn2_icon" />
    </a>
    <a
      href="#"
      class="ml-social-btn2 mr-6"
      @click.prevent="selectChannel(7)"
      v-if="isViber"
      :class="{ active: channel === 7 }"
    >
      <SvgViber class="ml-social-btn2_icon" />
    </a>
    <a
      href="#"
      class="ml-social-btn2"
      @click.prevent="selectChannel(1)"
      v-if="isSms"
      :class="{ active: channel === 1 }"
    >
      <SvgSms class="ml-social-btn2_icon" />
    </a>
  </div>
</template>

<script>
import SvgTelegram from '@/components/UI/SVG/Telegram'
import SvgWhatsApp from '@/components/UI/SVG/WhatsApp'
import SvgViber from '@/components/UI/SVG/Viber'
import SvgSms from '@/components/UI/SVG/Sms'
import { mapState } from 'vuex'

export default {
  components: { SvgTelegram, SvgWhatsApp, SvgViber, SvgSms },
  props: {
    channel: {
      type: Number,
      default: 1
    }
  },
  computed: {
    ...mapState({
      channels: state => state?.app?.pageContactConfig?.Communication
    }),
    isTelegram() {
      const find = this.channels?.find(
        x =>
          x?.Name?.toLowerCase() ===
          this.$const?.SEND_METHOD?.TELEGRAM?.NAME?.toLowerCase()
      )
      if (!find) return false
      return find?.Shown === true
    },
    isSms() {
      const find = this.channels?.find(
        x =>
          x?.Name?.toLowerCase() ===
          this.$const?.SEND_METHOD?.SMS?.NAME?.toLowerCase()
      )
      if (!find) return false
      return find?.Shown === true
    },
    isViber() {
      const find = this.channels?.find(
        x =>
          x?.Name?.toLowerCase() ===
          this.$const?.SEND_METHOD?.VIBER?.NAME?.toLowerCase()
      )
      if (!find) return false
      return find?.Shown === true
    },
    isWhatsapp() {
      const find = this.channels?.find(
        x =>
          x?.Name?.toLowerCase() ===
          this.$const?.SEND_METHOD?.WHATSAPP?.NAME?.toLowerCase()
      )
      if (!find) return false
      return find?.Shown === true
    }
  },
  methods: {
    selectChannel(id) {
      this.$emit('select-channel', id)
    }
  }
}
</script>

<template>
  <div class="ml-panel-sending-page d-flex flex-column pb-0 h100">
    <div class="pb flex-grow-1">
      <div class="pt-0">
        <div class="section mb-4">Отправка</div>
        <div class="ml-text-16-24-600 mb-2">Ваши контакты</div>
        <div class="ml-text-14-16 ml-text-grey mb-4">
          Эти данные нужны для отправки фискального чека и оперативного
          реагирования техподдержки.
        </div>
      </div>
      <div>
        <v-form ref="form" v-model="form.valid">
          <v-text-field
            v-if="showName"
            ref="name"
            color="dark"
            append-icon="mdi-check"
            type="text"
            required
            :rules="nameRules"
            height="60"
            label="Ваше имя*"
            class="ml-input ml-hide-details mb-1"
            autocomplete="off"
            :class="{ novalidate: validateName === false }"
            outlined
            v-model="form.name"
          ></v-text-field>
          <v-text-field
            color="dark"
            append-icon="mdi-check"
            type="email"
            required
            :rules="emailRules"
            height="60"
            label="Ваш e-mail*"
            class="ml-input ml-hide-details mb-1"
            autocomplete="off"
            :class="{ novalidate: validateEmail === false }"
            outlined
            v-model="form.email"
          ></v-text-field>
        </v-form>
      </div>
      <div>
        <div class="d-flex flex-column pb-0">
          <div class="d-flex flex-column">
            <v-text-field
              v-if="!showEditablePhone"
              ref="phone"
              autocomplete="off"
              color="dark"
              prepend-inner-icon="+7"
              :append-icon="phoneAppendIcon"
              v-mask="'(###) ###-##-##'"
              required
              height="60"
              label="Ваш телефон*"
              class="ml-input ml-input-prepend-inner ml-hide-details mb-3"
              :rules="phoneRules"
              :class="{ novalidate: validatePhone === false }"
              outlined
              :disabled="isSentVerificationCode"
              v-model="form.phone"
              @keydown.enter="requestCode"
              @click:append="editPhone"
              @focus="onFocusPhone"
            >
            </v-text-field>
            <v-text-field
              v-else
              autocomplete="off"
              ref="phone-editable"
              color="dark"
              prepend-inner-icon="+7"
              append-icon="mdi-content-save"
              v-mask="'(###) ###-##-##'"
              required
              clearable
              height="60"
              label="Ваш телефон*"
              class="ml-input ml-input-prepend-inner ml-hide-details mb-3"
              :class="{ novalidate: validatePhone === false }"
              outlined
              :disabled="isSentVerificationCode"
              v-model="phoneEditable"
              @keydown.enter="saveNewPhone"
              @click:append="saveNewPhone"
              @focus="onFocusPhone"
              @click:clear="cancelEditPhone"
            >
            </v-text-field>
          </div>

          <template v-if="!successVerification && !isSentVerificationCode">
            <button
              :disabled="!validatePhone"
              href="#"
              class="ml-black-btn w100 mb-2"
              @click.stop="requestCode"
            >
              Подтвердить телефон
            </button>
            <div class="ml-text-14-16 ml-text-grey ">
              Нажимая «Подтвердить телефон», Вы соглашаетесь с
              <!-- <a href="#" @click.prevent="goOffer">
                <u>Правилами</u>
              </a>
              и -->
              <a href="#" @click.prevent="goOffer">
                <u>Офертой.</u>
              </a>
            </div>
          </template>
        </div>
      </div>
      <verification-code
        @success="successVerificationProcess"
        v-if="isSentVerificationCode && !successVerification"
        :type="channelType"
        :phone="form.phone"
        :email="form.email"
        :name="form.name"
        :codeType="smsCodeType"
      />
      <template v-if="successVerification">
        <div class="mb-5">
          <v-icon class="success-message">mdi-check-circle</v-icon>
          <span class="ml-2">Номер подтвержден!</span>
        </div>
        <template v-if="isChannelInfo">
          <div class="ml-text-16-24-600 mb-2">
            Канал информирования
          </div>
          <div class="ml-text-14-16 ml-text-grey mb-4">
            Выберите, где хотите получать информацию о состоянии заказа и
            сертификатах, которые Вы подарили
          </div>
          <SelectChannel
            class="mb-8"
            :channel="form.channel"
            @select-channel="form.channel = $event"
          />
        </template>
      </template>
    </div>
    <div
      class="controlls"
      ref="controlls"
      v-show="successVerification === true"
    >
      <button
        @click.prevent="nextPage"
        class="ml-black-btn"
        style="width: 100%"
      >
        Перейти к оформлению
      </button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex'
// import {VERIFICATION_BY_SMS,VERIFICATION_BY_MESSENGER } from '@/helpers/const/sendingMethod'
import verificationTypes from '@/store/verificationCode/types'
import { mask } from 'vue-the-mask'
import verificationCode from '@/components/Panel/VerificationCode'
import MixinChangePanelPage from '@/helpers/mixins/panel/changePage'
import MixinCommon from '@/helpers/mixins/common'
import { cloneDeep } from 'lodash'
import Constants from '@/helpers/constants'
import MixinObserveElement from '@/helpers/mixins/observeElement'
import SelectChannel from '@/components/SelectChannel'
import MixinChagePage from '@/helpers/mixins/burger/changePage'
import { OFFER } from '@/helpers/const/widgetPage'
import panelBurgerTypes from '@/store/panelBurger/types'
import navTypes from '@/store/navigation/types'

export default {
  directives: { mask },
  components: {
    verificationCode,
    SelectChannel
  },
  mixins: [
    MixinChangePanelPage,
    MixinObserveElement,
    MixinChagePage,
    MixinCommon
  ],
  data: () => ({
    form: {
      valid: false,
      email: null,
      name: null,
      phone: null,
      // email: 'qwe@qwe',
      // name: 'qwe',
      // phone: '9224870500',
      // sendingMethod: null,
      channel: 6
    },
    phoneEditable: null,
    allowEditPhone: false,
    successVerification: null,
    // successVerification: true,
    isSentVerificationCode: false, //Признак того что отправили код, т.е. нажали на кнопку "Подтвердить"
    // isSentVerificationCode: true,
    emailRules: [
      v => !!v || 'Необходимо заполнить e-mail',
      v => /.+@.+/.test(v) || 'Введен некорректный e-mail'
    ],
    nameRules: [v => !!v || 'Необходимо заполнить имя'],
    phoneRules: [
      v =>
        v?.startsWith('(9') ||
        v?.includes('(666) 666-66-66') ||
        'Введите корректный телефон'
    ]
  }),
  mounted() {
    this.setInitialize()
  },
  destroyed() {
    this[verificationTypes.SET_EDIT_CONTACT_MODE](false)
  },
  computed: {
    ...mapState({
      showName: state => state?.app?.pageContactConfig?.FieldNameShown ?? false,
      isEditMode: state => state?.verificationCode?.isEditContactMode,
      contacts: state => state?.verificationCode?.contacts,
      config: state => state?.app?.pageContactConfig
    }),
    isChannelInfo() {
      return this.config?.ChannelInformation
    },
    observedElement() {
      return this.$refs.controlls
    },
    smsCodeType() {
      return Constants?.SMS_CODE_TYPE?.SERT_BUYER // CodeType = 1
    },
    phoneAppendIcon() {
      if (this.isEditMode) return 'mdi-pencil-outline'
      return 'mdi-check'
    },
    validateForm() {
      if (!this.showName) {
        return this.validateEmail && this.validatePhone
      }
      return this.validateEmail && this.validatePhone && this.validateName
    },
    validateForm2() {
      if (!this.showName) {
        return this.validateEmail
      }
      return this.validateEmail && this.validateName
    },
    channelType() {
      return Constants?.SEND_METHOD?.SMS?.COMMUNICATION_TYPE // = 1
      // return this.isSendingMethodSms ? VERIFICATION_BY_SMS : VERIFICATION_BY_MESSENGER
    },
    validateEmail() {
      return /.+@.+/.test(this.form.email)
    },
    validateName() {
      return this.form?.name?.length > 0
    },
    validatePhone() {
      if (!this.isEditMode) {
        return this.form?.phone?.length === 15 && this.validatePhone6or9()
      }
      return this.phoneEditable?.length === 15
    },
    showEditablePhone() {
      if (this.isEditMode && this.allowEditPhone === true) {
        return true
      }
      return false
    }
  },
  methods: {
    // ...mapMutations('app', [appTypes.SET_OPACITY]),
    ...mapMutations('verificationCode', [
      verificationTypes.SET_CONTACTS,
      verificationTypes.SET_EDIT_CONTACT_MODE
    ]),
    ...mapActions('verificationCode', [verificationTypes.REQUEST_CODE]),
    ...mapMutations('panelBurger', [panelBurgerTypes.TOGGLE_PANEL_BURGER]),
    ...mapMutations('navigation', [
      navTypes.SET_COMMAND,
      navTypes.SET_BACK_URL
    ]),
    validatePhone6or9() {
      return (
        this.form.phone.startsWith('(9') ||
        this.form.phone.includes('(666) 666-66-66')
      )
    },
    togglePanelBurger() {
      this[panelBurgerTypes.TOGGLE_PANEL_BURGER]()
    },
    goOffer() {
      this[navTypes.SET_COMMAND](this.$const?.NAV_COMMANDS?.CLOSE_BURGER)
      // this[navTypes.SET_BACK_URL]({ name: 'Basket' })
      this.togglePanelBurger()
      this.changePanelBurgerPage(OFFER)
    },
    saveNewPhone() {
      if (this.phoneEditable !== this.contacts?.phone) {
        this.successVerification = false
      }
      this.allowEditPhone = false
      setTimeout(() => {
        this.form.phone = this.phoneEditable
        this.$refs?.phone?.blur()
      })
    },
    cancelEditPhone() {
      this.allowEditPhone = false
      setTimeout(() => {
        this.form.phone = this.contacts?.phone
        this.$refs?.phone?.blur()
      })
    },
    onFocusPhone() {
      if (this.isEditMode && this.allowEditPhone === false) {
        this.$refs?.phone?.blur()
      }
    },
    setInitialize() {
      this.showNavs(true)
      if (this.isEditMode) {
        this.form.email = this.contacts?.email
        this.form.name = this.contacts?.name
        this.form.phone = this.contacts?.phone
        this.phoneEditable = cloneDeep(this.contacts?.phone)
        // this.form.sendingMethod = this.contacts?.sendingMethod
        this.successVerification = true
        // this.isSentVerificationCode = true
        this.$nextTick(() => {
          this.handleScroll()
        })
      }
    },
    editPhone() {
      this.allowEditPhone = true
      this.$nextTick(() => {
        this.$refs['phone-editable']?.focus()
      })
    },
    async success1() {
      return new Promise(resolve => {
        this.successVerification = true
        this[verificationTypes.SET_CONTACTS](this.form)
        this[verificationTypes.SET_EDIT_CONTACT_MODE](false)
        // setTimeout(() => {
        return resolve()
        // }, 500)
      })
    },
    async success2() {
      return new Promise(resolve => {
        window.scrollTo({
          top: window.innerHeight,
          left: 0,
          behavior: 'smooth'
        })
        // this[appTypes.SET_OPACITY](1)
        this.showNavs(true)
        // setTimeout(() => {
        return resolve()
        // }, 500)
      })
    },

    async successVerificationProcess() {
      // this[appTypes.SET_OPACITY](0)
      this.showNavs(false)
      await this.success1()
      await this.success2()
      // setTimeout(() => {
      // setTimeout(() => {
      // this.successVerification = true
      // this[verificationTypes.SET_CONTACTS](this.form)
      // this[verificationTypes.SET_EDIT_CONTACT_MODE](false)
      // }, 250)
      // }, 500)
      // setTimeout(() => {

      // }, 600)
      // setTimeout(() => {
      this.handleScroll()
      // }, 1000)
    },
    requestCode() {
      // this.successVerificationProcess()
      // return
      const phone = this.$helper.getClearPhone(this.form.phone)
      const communicationType = 1
      const codeType = this.smsCodeType
      if (phone && communicationType && codeType) {
        this[verificationTypes.REQUEST_CODE]({
          phone,
          communicationType,
          codeType
        }).then(() => {
          this.isSentVerificationCode = true
          this.$refs.form.validate()
        })
      }
    },
    nextPage() {
      this.showNavs(false)
      setTimeout(() => {
        this.$router.push('/confirming')
      }, 250)
    }
  }
}
</script>
